import { createApp } from 'vue';
import App from './App.vue';
import { createRouter, createWebHistory } from 'vue-router';
import Home from './App.vue';
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css'
import BootstrapVueNext from 'bootstrap-vue-next'
import { library } from '@fortawesome/fontawesome-svg-core';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { initializeApp } from "firebase/app"

library.add(faLock);

// Firebase
const firebaseConfig = {
  "apiKey": "AIzaSyC5xU9yyf7_rSYknteGhlOvE5T2BY1uRY8",
  "authDomain": "model-viewer-14977.firebaseapp.com",
  "databaseURL": "https://model-viewer-14977.firebaseio.com",
  "projectId": "model-viewer-14977",
  "storageBucket": "model-viewer-14977.appspot.com",
  "messagingSenderId": "238437615529",
  "appId": "1:238437615529:web:6003e88259ec91825526b8",
  "measurementId": "G-Q3K2ZV7SMV"
}

initializeApp(firebaseConfig)

// Define routes
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  // ... other routes
];

// Create router instance
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});


// Create and mount the Vue instance
const app = createApp(App).use(router).use(BootstrapVueNext);
app.component('font-awesome-icon', FontAwesomeIcon);
app.mount('#app')
